import React from "react";

const InstructionComponent = ({ questions, answers }) => {
  const containerStyle = {
    margin: "auto",
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const questionStyle = {
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const answerStyle = {
    marginBottom: "20px",
  };

  return (
    <div style={containerStyle}>
      {questions?.map((question) => (
        <div key={question.label}>
          <p style={questionStyle}>{question?.question}</p>
          <p style={answerStyle}>
            {answers?.[0]?.[question?.label]
              ? answers?.[0]?.[question?.label]
              : "Didn't Answered"}
          </p>
        </div>
      ))}
    </div>
  );
};

export default InstructionComponent;
