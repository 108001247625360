import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sildebar";
import Users from "./components/Users/Users";
import Profile from "./components/Profile/Profile";
import Sessions from "./components/Sessions/Sessions";
import Questions from "./components/Questions/Questions";
import Answers from "./components/Answers/Answers";
import Box from "@mui/material/Box";
import Auth from "./components/Auth/Auth";
import CoachApplications from "./components/CoachApplications/CoachApplications";
import ApplicationDetails from "./components/ApplicationDetails/ApplicationDetails";
import Dashboard from "./components/Dashboard/Dashboard";
import Referrals from "./components/Referrals/Referrals";
import Coaches from "./components/Coaches/Coaches";
import CoachContent from "./components/CoachContent/CoachContent";
import ContentDetails from "./components/ContentDetails/ContentDetails";

function App() {
  const [open, setOpen] = React.useState(false);
  const password = localStorage.getItem("wellavipassword");
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [title, setTitle] = React.useState("Dashboard");

  useEffect(() => {
    if (password === process.env.REACT_APP_PASSWORD) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      <Router>
        {isLoggedIn ? (
          <Box sx={{ display: "flex" }}>
            <Sidebar
              open={open}
              setOpen={setOpen}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              title={title}
            />
            <Routes>
              <Route
                exact
                path="/"
                element={<Dashboard open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/dashboard"
                element={<Dashboard open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/users"
                element={<Users open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/coaches"
                element={<Coaches open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/coachcontent"
                element={<CoachContent open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/contentdetails/:content_type/:content_id"
                element={<ContentDetails open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/applications"
                element={<CoachApplications open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/applicationdetails/:id"
                element={<ApplicationDetails open={open} setTitle={setTitle} />}
              />
              <Route exact path="/profile" element={<Profile open={open} />} />
              <Route
                exact
                path="/referrals"
                element={<Referrals open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/sessions"
                element={<Sessions open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/questions"
                element={<Questions open={open} setTitle={setTitle} />}
              />
              <Route
                exact
                path="/answers"
                element={<Answers open={open} setTitle={setTitle} />}
              />
            </Routes>
          </Box>
        ) : (
          <Auth isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        )}
      </Router>
    </>
  );
}

export default App;
