import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { Box } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import { Main } from "../Sidebar/MainBody/Main";
import { CSVLink } from "react-csv";
import Options from "../Questions/Options";

import "./Sessions.css";

function Sessions({ open, setTitle }) {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);

  const [data, setData] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [approvedSessions, setApprovedSessions] = useState([]);
  const [pendingSessions, setPendingSessions] = useState([]);
  const [rejectedSessions, setRejectedSessions] = useState([]);
  const [completedSessions, setCompletedSessions] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState("allSessions");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URI}user/sessions`)
      .then((response) => {
        const sessions = response.data;
        setData(sessions);
        setAllSessions(sessions);
        setApprovedSessions(
          sessions.filter((session) => session.is_active === "APPROVED")
        );
        setPendingSessions(
          sessions.filter((session) => session.is_active === "PENDING")
        );
        setRejectedSessions(
          sessions.filter((session) => session.is_active === "REJECTED")
        );
        setCompletedSessions(
          sessions.filter((session) => session.is_active === "COMPLETED")
        );
      })
      .catch((error) => {
        console.log(error);
      });
    setTitle("Sessions");
  }, []);

  const columns = useMemo(
    () => [
      { field: "coach_f_name", headerName: "Coach First Name", flex: 1 },
      { field: "coach_l_name", headerName: "Coach Last Name", flex: 1 },
      { field: "user_f_name", headerName: "User First Name", flex: 1 },
      { field: "user_l_name", headerName: "User Last Name", flex: 1 },
      { field: "price", headerName: "Price", flex: 1 },
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        renderCell: (params) => moment(params.row.date).format("MM-DD-YYYY"),
      },
      {
        field: "result",
        headerName: "Dimension",
        flex: 1,
        height: 100,
        renderCell: (params) =>
          params.row?.result === null ? null : (
            <Options options={params?.row?.result?.result} type={"session"} />
          ),
      },
      { field: "is_active", headerName: "Status", flex: 1 },
    ],
    [rowId]
  );

  const handleData = (type, containerName) => {
    setData(type);
    setSelectedContainer(containerName);
  };

  const convertToCSVData = (data) => {
    const csvData = data.map((row) => ({
      "Coach First Name": row.coach_f_name,
      "Coach Last Name": row.coach_l_name,
      "User First Name": row.user_f_name,
      "User Last Name": row.user_l_name,
      Price: row.price,
      Dimension: row.dimension,
      Status: row.is_active,
      Date: moment(row.date).format("MM-DD-YYYY"),
    }));

    return csvData;
  };
  return (
    <>
      <Main open={open} style={{ background: "#f6f6f6", height: "100vh" }}>
        <DrawerHeader />
        <Box
          style={{
            height: 600,
            width: "100%",
          }}
        >
          <div className="count_cards">
            <div
              className={`count_container ${
                selectedContainer === "allSessions" ? "selected" : ""
              }`}
              onClick={() => handleData(allSessions, "allSessions")}
            >
              <div>
                <h3>All</h3>
                <h3>{allSessions.length}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "approvedSessions" ? "selected" : ""
              }`}
              onClick={() => handleData(approvedSessions, "approvedSessions")}
            >
              <div>
                <h3>Approved</h3>
                <h3>{approvedSessions.length}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "pendingSessions" ? "selected" : ""
              }`}
              onClick={() => handleData(pendingSessions, "pendingSessions")}
            >
              <div>
                <h3>Pending</h3>
                <h3>{pendingSessions.length}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "rejectedSessions" ? "selected" : ""
              }`}
              onClick={() => handleData(rejectedSessions, "rejectedSessions")}
            >
              <div>
                <h3>Rejected</h3>
                <h3>{rejectedSessions.length}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "completedSessions" ? "selected" : ""
              }`}
              onClick={() => handleData(completedSessions, "completedSessions")}
            >
              <div>
                <h3>Completed</h3>
                <h3>{completedSessions.length}</h3>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "right", padding: "1rem" }}>
            <CSVLink
              data={convertToCSVData(data)}
              filename={"sessions_data.csv"}
              className="csv-download-link"
            >
              Export to CSV
            </CSVLink>
          </div>
          <DataGrid
            columns={columns}
            rows={data}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={(params) => ({
              top: params.isFirstVisible ? 0 : 5,
              bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
              [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[50] : grey[900],
              },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
          />
        </Box>
      </Main>
    </>
  );
}

export default Sessions;
