import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { Box } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { Main } from "../Sidebar/MainBody/Main";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import { CSVLink } from "react-csv";

import "./Users.css";

function Users({ open, setTitle }) {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [daily, setDaily] = useState([]);
  const [weekly, setWeekly] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [quarter, setQuarter] = useState([]);
  const [yearly, setYearly] = useState([]);
  const [notOnBoarded, setNotOnBoarded] = useState([]);
  const [sub, setSub] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState("users");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URI}user/all`)
      .then((response) => {
        setData(response.data);
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}user/daily-user-signups`)
      .then((response) => {
        setDaily(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}user/weekly-user-signups`)
      .then((response) => {
        setWeekly(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}user/monthly-user-signups`)
      .then((response) => {
        setMonthly(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}user/quarter-user-signups`)
      .then((response) => {
        setQuarter(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}user/ytd-user-signups`)
      .then((response) => {
        setYearly(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}user/not-on-boarded`)
      .then((response) => {
        setNotOnBoarded(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}user/subscribed-users`)
      .then((response) => {
        setSub(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setTitle("Users");
  }, []);

  const columns = useMemo(
    () => [
      { field: "first_name", headerName: "First Name", flex: 1 },
      { field: "last_name", headerName: "Last Name", flex: 1 },
      { field: "email", headerName: "Email", flex: 1 },
      {
        field: "role",
        headerName: "Role",
        flex: 1,
      },
      {
        field: "is_user_assessment_completed",
        headerName: "TrueSelf",
        flex: 1,
        renderCell: (params) =>
          params.row.is_user_assessment_completed === true ? "Yes" : "No",
      },
      { field: "country", headerName: "Location", flex: 1 },
      ...(selectedContainer === "sub"
        ? [{ field: "subscription_name", headerName: "Subscription", flex: 1 }]
        : []),
      {
        field: "createdAt",
        headerName: "Sign Up ",
        flex: 1,
        renderCell: (params) =>
          moment(params.row.createdAt).format("MM-DD-YYYY"),
      },
    ],
    [rowId, selectedContainer]
  );

  const handleData = (type, containerName) => {
    setData(type);
    setSelectedContainer(containerName);
  };

  const convertToCSVData = (data) => {
    const csvData = data.map((row) => ({
      "First Name": row.first_name,
      "Last Name": row.last_name,
      Email: row.email,
      Role: row.role,
      TrueSelf: row.is_user_assessment_completed ? "Yes" : "No",
      Location: row.country,
      "Sign Up": moment(row.createdAt).format("MM-DD-YYYY"),
    }));

    return csvData;
  };

  return (
    <>
      <Main open={open} style={{ background: "#f6f6f6", height: "100vh" }}>
        <DrawerHeader />
        <Box
          style={{
            height: 600,
            width: "100%",
          }}
        >
          <div className="count_cards">
            <div
              className={`count_container ${
                selectedContainer === "users" ? "selected" : ""
              }`}
              onClick={() => handleData(users, "users")}
            >
              <div>
                <h3>Total</h3>
                <p>{users.length}</p>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "daily" ? "selected" : ""
              }`}
              onClick={() => handleData(daily, "daily")}
            >
              <div>
                <h3>Today</h3>
                <p>{daily.length}</p>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "weekly" ? "selected" : ""
              }`}
              onClick={() => handleData(weekly, "weekly")}
            >
              <div>
                <h3>Singups This Week</h3>
                <p>{weekly.length}</p>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "monthly" ? "selected" : ""
              }`}
              onClick={() => handleData(monthly, "monthly")}
            >
              <div>
                <h3>Singups This Month</h3>
                <p>{monthly.length}</p>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "quarter" ? "selected" : ""
              }`}
              onClick={() => handleData(quarter, "quarter")}
            >
              <div>
                <h3>Signups This Quarter</h3>
                <p>{quarter.length}</p>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "yearly" ? "selected" : ""
              }`}
              onClick={() => handleData(yearly, "yearly")}
            >
              <div>
                <h3>Singups This Year</h3>
                <p>{yearly.length}</p>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "notOnBoarded" ? "selected" : ""
              }`}
              onClick={() => handleData(notOnBoarded, "notOnBoarded")}
            >
              <div>
                <h3>Not OnBoarded</h3>
                <p>{notOnBoarded.length}</p>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "sub" ? "selected" : ""
              }`}
              onClick={() => handleData(sub, "sub")}
            >
              <div>
                <h3>Subscribed</h3>
                <p>{sub.length}</p>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "right", padding: "1rem" }}>
            <CSVLink
              data={convertToCSVData(data)}
              filename={"users_data.csv"}
              className="csv-download-link"
            >
              Export to CSV
            </CSVLink>
          </div>
          <DataGrid
            columns={columns}
            rows={data}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={(params) => ({
              top: params.isFirstVisible ? 0 : 5,
              bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
              [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[50] : grey[900],
              },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
          />
        </Box>
      </Main>
    </>
  );
}

export default Users;
