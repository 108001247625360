import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Main } from "../Sidebar/MainBody/Main";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import AssessmentComponent from "./AssessmentComponent";

import selfAssessmentQuestion from "./self_assesment.json";
import instructions from "./instructions.json";
import specificQuestions from "./specific_questions.json";

import InstructionComponent from "./InstructionComponent";
import QuestionAnswerComponent from "./QuestionAnswerComponent";
import "./ApplicationDetails.css";

const GetApiCall = async (url, header) => {
  const rawResponse = await fetch(url, {
    method: "GET",
    headers: header,
  })
    .then((r) => r.json())
    .catch((exc) => {
      return null;
    });

  return rawResponse;
};

function ApplicationDetails({ open, setTitle }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [profile, setProfile] = useState([]);
  const [valueSelect, setValueSelect] = useState("");
  const [imgLink, setImgLink] = useState();
  const [openA, setOpenA] = React.useState(false);
  const [openR, setOpenR] = React.useState(false);
  const [openI, setOpenI] = React.useState(false);
  const [error, setError] = React.useState(false);
  const reasonMock = [
    {
      id: 1,
      name: "Reason 1",
    },
  ];
  const [reason, setReason] = useState(reasonMock);

  const handleClickOpenA = () => {
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleClickOpenI = (link) => {
    setOpenI(true);
    setImgLink(link);
  };

  const handleCloseI = () => {
    setOpenI(false);
  };

  const handleClickOpenR = () => {
    setOpenR(true);
  };

  const handleCloseR = () => {
    setOpenR(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setValueSelect(value);
    setError(false);
  };

  const PUTApiCall = async (url, payLoad, header, manageApiResponse = true) => {
    if (!!payLoad && Object.keys(payLoad).length > 0) {
      const rawResponse = await fetch(url, {
        method: "PUT",
        headers: header,
        body: payLoad,
      })
        .then((r) => {
          return r.json();
        })
        .catch((exc) => {
          return null;
        });
      if (!manageApiResponse) {
        return null;
      } else if (rawResponse === null) {
        return null;
      } else if (rawResponse.status_code === undefined) {
        return rawResponse;
      } else if (
        rawResponse.status_code === 200 ||
        rawResponse.status_code === 101
      ) {
        return rawResponse;
      } else {
        return null;
      }
    } else {
      const rawResponse = await fetch(url, {
        method: "PUT",
        headers: header,
      })
        .then((r) => r.json())
        .catch((exc) => {
          return null;
        });

      if (!manageApiResponse) {
        return null;
      } else if (rawResponse === null) {
        return null;
      } else if (rawResponse.status_code === undefined) {
        return rawResponse;
      } else if (
        rawResponse.status_code === 200 ||
        rawResponse.status_code === 101
      ) {
        return rawResponse;
      } else {
        return null;
      }
    }
  };

  const updateCoachNew = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const data = await PUTApiCall(
      `${process.env.REACT_APP_WELLAVI_API_URI}/users/update-coach-status`,
      JSON.stringify(payload),
      headers
    );

    if (data?.status == 200) {
      alert("Status updated successfully");
      navigate(`/applications`);
    }
  };

  const getReason = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const data = await GetApiCall(
      `${process.env.REACT_APP_WELLAVI_API_URI}/reasoning`,
      headers
    );

    if (data?.status == 200) {
      setReason(data.data);
    }
  };

  const handleAction = async (action) => {
    const requestData = {
      user_id: id,
      coach_status: action,
      reason_id: valueSelect,
    };
    if (action === "REJECTED" && valueSelect === "") {
      setError(true);
    } else {
      updateCoachNew(requestData);
    }
  };

  useEffect(() => {
    const variable = { user_id: id };
    axios
      .post(
        `${process.env.REACT_APP_API_URI}coach/applicationdetails`,
        variable
      )
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    getReason();
    setTitle("Coaches > Applications > Profile View");
  }, []);

  return (
    <Main
      open={open}
      style={{
        background: "#F6F6F6",
      }}
    >
      <DrawerHeader />
      <div className="profile-container">
        <div className="profile-section">
          <div className="buttons-container">
            <button className="accept-button" onClick={handleClickOpenA}>
              Approve
            </button>
            <button className="reject-button" onClick={handleClickOpenR}>
              Reject
            </button>
          </div>
          <div className="profile_content_container">
            <div className="profile_content_container_top">
              <div className="left_section">
                <div className="content_container">
                  <p className="content_title">Languages</p>
                  <div className="content_cards">
                    {profile?.language?.map((lan) => (
                      <p className="content_bg">{lan}</p>
                    ))}
                  </div>
                </div>

                <div className="content_container">
                  <p className="content_title">Location</p>
                  <div className="content_cards">
                    <p className="content_bg">{profile?.country}</p>
                  </div>
                </div>

                <div className="work">
                  <p>Work With Me</p>
                </div>
                <div className="content_container">
                  <p className="content_title">My Rate</p>
                  <div className="content_cards">
                    <p className="content_bg">{profile?.rate} per hour</p>
                  </div>
                </div>

                <div className="content_container">
                  <p className="content_title">Title</p>
                  <div className="content_cards">
                    <p className="content_bg">{profile?.title}</p>
                  </div>
                </div>

                <div className="content_container">
                  <p className="content_title">Specializations</p>
                  <div className="content_cards">
                    {profile?.specialization?.map((s) => (
                      <p className="content_bg">{s}</p>
                    ))}
                  </div>
                </div>

                <div className="work">
                  <p>My Certificates</p>
                </div>
                <div className="certificates">
                  {profile?.certificates?.[0] ? (
                    profile.certificates?.map((cer) => (
                      <div
                        className="img_container"
                        onClick={() => handleClickOpenI(cer)}
                      >
                        <img src={cer} />
                      </div>
                    ))
                  ) : (
                    <p className="content_title" style={{ marginTop: "15px" }}>
                      No certificates
                    </p>
                  )}
                </div>
              </div>
              <div className="right_section">
                <div className="info_container info_border">
                  <p className="info_title">Work</p>
                  <div className="info_description">
                    {profile?.experience?.[0] ? (
                      profile.experience?.map((ex) => (
                        <div className="box-content">
                          <h4>{ex.title}</h4>
                          <p>{ex.company_name}</p>
                          {ex.is_currently_working ? (
                            <p>{ex.start_year} - Present</p>
                          ) : (
                            <p>
                              {ex.start_year} - {ex.end_year}{" "}
                            </p>
                          )}
                        </div>
                      ))
                    ) : (
                      <p className="content_title">No data available</p>
                    )}
                  </div>
                </div>
                <div className="info_container info_border">
                  <p className="info_title">Education</p>
                  <div className="info_description">
                    {profile?.education?.[0] ? (
                      profile.education?.map((edu) => (
                        <div className="box-content">
                          <h4>{edu.school}</h4>
                          <p>
                            {edu.degree}, {edu.field_of_study}
                          </p>

                          <p>
                            {edu.start_year} - {edu.end_year}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p className="content_title">No data available</p>
                    )}
                  </div>
                </div>
                <div className="info_container info_border">
                  <p className="info_title">Specialty Areas</p>
                  <div className="info_description">
                    {profile?.coaching_areas?.length > 0 ? (
                      profile.coaching_areas?.map((c) => (
                        <div className="box-content">
                          <p>{c.label}</p>
                        </div>
                      ))
                    ) : (
                      <p className="content_title">No data available</p>
                    )}
                  </div>
                </div>
                <div className="info_container info_border">
                  <p className="info_title">Credentials</p>
                  <div className="info_description">
                    {profile?.credentials?.length > 0 ? (
                      profile.credentials?.map((c) => (
                        <div className="box-content">
                          <p>{c.label}</p>
                        </div>
                      ))
                    ) : (
                      <p className="content_title">No data available</p>
                    )}
                  </div>
                </div>
                <div className="info_container info_border">
                  <p className="info_title">Certifications</p>
                  <div className="info_description">
                    {profile?.certificates_metadata?.[0] ? (
                      profile.certificates_metadata?.map((cm) => (
                        <div className="box-content">
                          <h4>{cm.issuing_entity}</h4>
                          <h5>{cm.certification}</h5>
                          <p>
                            {cm.start_year} - {cm.end_year}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p className="content_title">No data available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_content_container_bottom">
              <div className="info_container about">
                <p className="info_title">About</p>
                <p className="info_description">{profile?.about}</p>
              </div>
              <div>
                <p className="assess-title">Coach's Self-Assessment:</p>
                {profile?.self_assessment &&
                profile?.self_assessment.length > 0 ? (
                  <AssessmentComponent
                    questions={selfAssessmentQuestion}
                    answers={profile?.self_assessment}
                  />
                ) : (
                  "Didn't Completed"
                )}
              </div>
              <div>
                <p className="assess-title">Instructions Answers:</p>

                {profile?.instructions ? (
                  <InstructionComponent
                    questions={instructions}
                    answers={profile?.instructions}
                  />
                ) : (
                  "Didn't Completed"
                )}
              </div>
              <div>
                <p className="assess-title">Specific Question's Answer:</p>

                {profile?.specific_questions &&
                profile?.specific_questions.length > 0 ? (
                  <QuestionAnswerComponent
                    jsonData={specificQuestions}
                    backendData={profile?.specific_questions}
                  />
                ) : (
                  "Didn't Completed"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openI}
        onClose={handleCloseI}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img src={imgLink} />
      </Dialog>

      <Dialog
        open={openA}
        onClose={handleCloseA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure want to approved this coach?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => handleAction("APPROVED")} autoFocus>
            Agree
          </Button>
          <Button onClick={handleCloseA}>Disagree</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Are you sure want to reject this coach? If yes then select a reason"
          }
        </DialogTitle>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <select onChange={handleChange} style={{ width: "93%" }}>
            {reason.map((reason) => (
              <option key={reason._id} value={reason._id}>
                {reason.name}
              </option>
            ))}
          </select>
        </div>
        {error && (
          <p
            style={{
              color: "#d71326",
              textAlign: "center",
              marginTop: "0.5rem",
            }}
          >
            Please select a reason first !!!
          </p>
        )}
        <DialogActions>
          <Button onClick={() => handleAction("REJECTED")} autoFocus>
            Agree
          </Button>
          <Button onClick={handleCloseR}>Disagree</Button>
        </DialogActions>
      </Dialog>
    </Main>
  );
}

export default ApplicationDetails;
