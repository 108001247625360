import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { Box } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { Main } from "../Sidebar/MainBody/Main";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import { CSVLink } from "react-csv";
import "./Coaches.css";

function Coaches({ open, setTitle }) {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);

  const [data, setData] = useState([]);
  const [daily, setDaily] = useState([]);
  const [weekly, setWeekly] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [quarter, setQuarter] = useState([]);
  const [yearly, setYearly] = useState([]);

  const [approved, setApproved] = useState([]);
  const [verified, setVerified] = useState([]);
  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [pendingPayouts, setPendingPayouts] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState("approved");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URI}coach/get-approved-coaches`)
      .then((response) => {
        setData(response.data);
        setApproved(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/daily-coach-signups`)
      .then((response) => {
        setDaily(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/weekly-coach-signups`)
      .then((response) => {
        setWeekly(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/monthly-coach-signups`)
      .then((response) => {
        setMonthly(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/quarter-coach-signups`)
      .then((response) => {
        setQuarter(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/ytd-coach-signups`)
      .then((response) => {
        setYearly(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/completed`)
      .then((response) => {
        setCompleted(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/get-onboard-start-coaches`)
      .then((response) => {
        setVerified(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URI}coach/get-pending-coaches`)
      .then((response) => {
        setPending(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}coach/pending-payouts`)
      .then((response) => {
        setPendingPayouts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setTitle("Coaches");
  }, []);

  const columns = useMemo(
    () => [
      { field: "first_name", headerName: "First Name", flex: 1 },
      { field: "last_name", headerName: "Last Name", flex: 1 },
      { field: "email", headerName: "Email", flex: 1 },
      { field: "coach_status", headerName: "Status", flex: 1 },
      {
        field: "profile",
        headerName: "Profile",
        flex: 1,
        renderCell: (params) => (params.row.profile === "coach" ? "Yes" : "No"),
      },
      {
        field: "is_coach_assessment_completed",
        headerName: "TrueCoach",
        flex: 1,
        renderCell: (params) =>
          params.row.is_coach_assessment_completed === true ? "Yes" : "No",
      },
      { field: "country", headerName: "Location", flex: 1 },
      {
        field: "connect_Account_approved",
        headerName: "Stripe",
        flex: 1,
        renderCell: (params) =>
          params.row.connect_Account_approved?.payouts_enabled === true
            ? "Yes"
            : "No",
      },
      {
        field: "createdAt",
        headerName: "Sign Up Date",
        flex: 1,
        renderCell: (params) =>
          moment(params.row.createdAt).format("MM-DD-YYYY"),
      },
    ],
    [rowId]
  );

  const handleData = (type, containerName) => {
    setData(type);
    setSelectedContainer(containerName);
  };

  const convertToCSVData = (data) => {
    const csvData = data.map((row) => ({
      "First Name": row.first_name,
      "Last Name": row.last_name,
      Email: row.email,
      Status: row.coach_status,
      Profile: row.profile,
      TrueSelf: row.is_coach_assessment_completed ? "Yes" : "No",
      Location: row.country,
      Stripe: row.stripe,
      "Sign Up": moment(row.createdAt).format("MM-DD-YYYY"),
    }));

    return csvData;
  };

  return (
    <Main open={open} style={{ background: "#f6f6f6", height: "100vh" }}>
      <DrawerHeader />
      <Box
        style={{
          height: 600,
          width: "100%",
        }}
      >
        <div className="count_cards">
          <div
            className={`count_container ${
              selectedContainer === "approved" ? "selected" : ""
            }`}
            onClick={() => handleData(approved, "approved")}
          >
            <div>
              <h3>Approved</h3>
              <h3>{approved.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "daily" ? "selected" : ""
            }`}
            onClick={() => handleData(daily, "daily")}
          >
            <div>
              <h3>Today</h3>
              <h3>{daily.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "weekly" ? "selected" : ""
            }`}
            onClick={() => handleData(weekly, "weekly")}
          >
            <div>
              <h3>Singups This Week</h3>
              <h3>{weekly.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "monthly" ? "selected" : ""
            }`}
            onClick={() => handleData(monthly, "monthly")}
          >
            <div>
              <h3>Singups This Month</h3>
              <h3>{monthly.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "quarter" ? "selected" : ""
            }`}
            onClick={() => handleData(quarter, "quarter")}
          >
            <div>
              <h3>Singups This Quarter</h3>
              <h3>{quarter.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "yearly" ? "selected" : ""
            }`}
            onClick={() => handleData(yearly, "yearly")}
          >
            <div>
              <h3>Singups This Year</h3>
              <h3>{yearly.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "pending" ? "selected" : ""
            }`}
            onClick={() => handleData(pending, "pending")}
          >
            <div>
              <h3>Pending</h3>
              <h3>{pending.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "verified" ? "selected" : ""
            }`}
            onClick={() => handleData(verified, "verified")}
          >
            <div>
              <h3>Incomplete</h3>
              <h3>{verified.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "completed" ? "selected" : ""
            }`}
            onClick={() => handleData(completed, "completed")}
          >
            <div>
              <h3>Completed</h3>
              <h3>{completed.length}</h3>
            </div>
          </div>
          <div
            className={`count_container ${
              selectedContainer === "pendingPayouts" ? "selected" : ""
            }`}
            onClick={() => handleData(pendingPayouts, "pendingPayouts")}
          >
            <div>
              <h3>Pending Payouts</h3>
              <h3>{pendingPayouts.length}</h3>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "right", padding: "1rem" }}>
          <CSVLink
            data={convertToCSVData(data)}
            filename={"coaches_data.csv"}
            className="csv-download-link"
          >
            Export to CSV
          </CSVLink>
        </div>
        <DataGrid
          columns={columns}
          rows={data}
          getRowId={(row) => row._id}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[50] : grey[900],
            },
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </Main>
  );
}

export default Coaches;
