// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* User.css */
.count_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.count_container {
  width: 154px;
  height: 51px;
  color: #111827;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  background-color: #fff;
  font-weight: 500;
}
.count_container.selected {
  background-color: #792f8a;
  color: #ffffff;
}
.count_container h3 {
  font-size: 14px;
}
.count_container p {
  font-size: 10px;
}
/* Add media query for smaller screens */
@media (max-width: 768px) {
  .count_container {
    width: 100%;
  }
}

/* Set responsive styles for DataGrid */
.MuiDataGrid-root {
  height: 400px;
  width: 100%;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .MuiDataGrid-root {
    height: 300px;
  }
}
/* Add this CSS in the same component's CSS file or in a separate CSS file imported into the component */
/* This will make the table responsive and scroll horizontally on smaller screens */

/* Adjust the breakpoint as needed */
@media screen and (max-width: 768px) {
  .MuiDataGrid-root {
    overflow: auto;
  }
}
.csv-download-link {
  text-decoration: none;
  color: #7f3391;
}
`, "",{"version":3,"sources":["webpack://./src/components/Users/Users.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA,wCAAwC;AACxC;EACE;IACE,WAAW;EACb;AACF;;AAEA,uCAAuC;AACvC;EACE,aAAa;EACb,WAAW;AACb;;AAEA,wCAAwC;AACxC;EACE;IACE,aAAa;EACf;AACF;AACA,wGAAwG;AACxG,mFAAmF;;AAEnF,oCAAoC;AACpC;EACE;IACE,cAAc;EAChB;AACF;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":["/* User.css */\n.count_cards {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  margin-bottom: 1.5rem;\n}\n\n.count_container {\n  width: 154px;\n  height: 51px;\n  color: #111827;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  align-items: center;\n  cursor: pointer;\n  margin: 10px;\n  background-color: #fff;\n  font-weight: 500;\n}\n.count_container.selected {\n  background-color: #792f8a;\n  color: #ffffff;\n}\n.count_container h3 {\n  font-size: 14px;\n}\n.count_container p {\n  font-size: 10px;\n}\n/* Add media query for smaller screens */\n@media (max-width: 768px) {\n  .count_container {\n    width: 100%;\n  }\n}\n\n/* Set responsive styles for DataGrid */\n.MuiDataGrid-root {\n  height: 400px;\n  width: 100%;\n}\n\n/* Add media query for smaller screens */\n@media (max-width: 768px) {\n  .MuiDataGrid-root {\n    height: 300px;\n  }\n}\n/* Add this CSS in the same component's CSS file or in a separate CSS file imported into the component */\n/* This will make the table responsive and scroll horizontally on smaller screens */\n\n/* Adjust the breakpoint as needed */\n@media screen and (max-width: 768px) {\n  .MuiDataGrid-root {\n    overflow: auto;\n  }\n}\n.csv-download-link {\n  text-decoration: none;\n  color: #7f3391;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
