import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import { Main } from "../Sidebar/MainBody/Main";

function Profile({ open }) {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [count, setCount] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URI}user/profile`)
      .then((response) => {
        setData(response.data.profile);
        setCount(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = useMemo(
    () => [
      { field: "user_id", headerName: "User Id", flex: 1 },
      { field: "title", headerName: "Title", flex: 1 },
      {
        field: "experience",
        headerName: "Employment Type",
        flex: 1,
        renderCell: (params) => params.row.experience[0]?.employment_type,
      },
      {
        field: "education",
        headerName: "Education",
        flex: 1,
        renderCell: (params) => params.row.education[0]?.degree,
      },
      { field: "about", headerName: "About", flex: 1 },
    ],
    [rowId]
  );

  return (
    <>
      <Main open={open}>
        <DrawerHeader />
        <Box
          style={{
            height: 600,
            width: "100%",
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            Profile ({count})
          </Typography>
          <DataGrid
            columns={columns}
            rows={data}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={(params) => ({
              top: params.isFirstVisible ? 0 : 5,
              bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
              [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[200] : grey[900],
              },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
          />
        </Box>
      </Main>
    </>
  );
}

export default Profile;
