import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const WeeklySignUpChart = ({ title, color, signupData }) => {
  const labels = signupData.map((data) => data.day);
  const counts = signupData.map((data) => data.count);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Sign Ups",
        data: counts,
        backgroundColor: `${color}`,
        borderColor: `${color}`,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: "category",
      },
      y: {
        beginAtZero: true,
        stepSize: 1,
        max: Math.max(...counts) + 1,
      },
    },
  };

  return (
    <div className="chart-container">
      <h5>Sign Up Count ({title})</h5>
      <div className="chart-wrapper">
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default WeeklySignUpChart;
