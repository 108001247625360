export const capitalizeText = (name) => {
  if (name?.length > 1) {
    return name.slice(0, 1).toUpperCase() + name.slice(1, name.length);
  }
  return name;
};

export const getStatus = (status) => {
  const modifiedStatus = status?.toLowerCase() || ''
  if (modifiedStatus === 'active') {
    return 'Approved'
  } else if (modifiedStatus === 'draft' || modifiedStatus === 'publish' || modifiedStatus === 'published') {
    return 'Pending'
  } else if (modifiedStatus === 'reject' || modifiedStatus === 'rejected') {
    return 'Rejected'
  }
  return
}