import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { Main } from "../Sidebar/MainBody/Main";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import { CSVLink } from "react-csv";

import "../Coaches/Coaches.css";
import { capitalizeText, getStatus } from "../utils";

function CoachContent({ open, setTitle }) {

  const navigate = useNavigate();
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const csvRef = useRef()
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalDataSize, setTotalDataSize] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const learnData = [
    {
      id: 1,
      type: 'all',
      totalItems: totalDataSize?.all?.totalItems,
      totalPages: totalDataSize?.all?.totalPages
    },
    {
      id: 2,
      type: 'read',
      totalItems: totalDataSize?.read?.totalItems,
      totalPages: totalDataSize?.read?.totalPages
    },
    {
      id: 3,
      type: 'listen',
      totalItems: totalDataSize?.listen?.totalItems,
      totalPages: totalDataSize?.listen?.totalPages
    },
    {
      id: 4,
      type: 'watch',
      totalItems: totalDataSize?.watch?.totalItems,
      totalPages: totalDataSize?.watch?.totalPages
    },
    {
      id: 5,
      type: 'todo',
      totalItems: totalDataSize?.todo?.totalItems,
      totalPages: totalDataSize?.todo?.totalPages
    },
  ]

  const [selectedContainer, setSelectedContainer] = useState(learnData[0]);

  useEffect(() => {
    setTitle("Coach Content");
    setMainLoading(true)
    const pageParam = searchParams.get('page')
    const pageSizeParam = searchParams.get('pageSize')
    const typeParam = searchParams.get('type')
    if (pageParam) {
      setPaginationModel(prev => ({ ...prev, page: parseInt(pageParam) }))
    }
    if (pageSizeParam) {
      setPaginationModel(prev => ({ ...prev, pageSize: parseInt(pageSizeParam) }))
    }
    if (typeParam) {
      setSelectedContainer(learnData.find(item => item.type == typeParam))
    }
    Promise.all(learnData.map(item => {
      let params = {
        page: 1,
        limit: 1
      }
      if (item.type !== 'all') {
        params.type = item.type
      }
      axios.get(`${process.env.REACT_APP_API_URI}content/learn-all`, { params: params })
        .then((response) => {
          if (response.status === 200) {
            setTotalDataSize(prev => ({ ...prev, [item.type]: { totalItems: response?.data?.totalItems, totalPages: response?.data?.totalPages } }))
          }
          setMainLoading(false)
        })
        .catch((error) => {
          setMainLoading(false)
          console.log(error);
        });
    }))
  }, [])

  const updateParams = (firstItem, pageItem) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('type', firstItem.type);
    newSearchParams.set('page', pageItem.page);
    newSearchParams.set('pageSize', pageItem.pageSize);
    setSearchParams(newSearchParams);
  }

  useEffect(() => {
    setLoading(true)
    let params = {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize
    }
    if (selectedContainer.type !== 'all') {
      params.type = selectedContainer.type
    }
    const controller = new AbortController();

    if (!mainLoading) {
      updateParams(selectedContainer, paginationModel)
      axios.get(`${process.env.REACT_APP_API_URI}content/learn-all`, { params: params, signal: controller.signal })
        .then((response) => {
          if (response.status === 200) {
            setTotalDataSize(prev => ({
              ...prev, currentCount: {
                totalItems: response?.data?.totalItems,
                totalPages: response?.data?.totalPages
              }
            }))
            setData(response?.data?.data)
          }
          setLoading(false)
        })
        .catch((error) => {
          if (error?.code !== "ERR_CANCELED") {
            setLoading(false)
          }
        });
    }
    return () => {
      controller.abort()
    }
  }, [selectedContainer, paginationModel, mainLoading]);


  const columns = useMemo(
    () => [
      {
        field: "first_name",
        headerName: "First Name",
        flex: 1,
        renderCell: (params) =>
          params.row.coach?.first_name || '',
      },
      {
        field: "last_name",
        headerName: "Last Name",
        flex: 1,
        renderCell: (params) =>
          params.row.coach?.last_name || '',
      },
      { field: "content_type", headerName: "Content Type", flex: 1 },

      {
        field: "createdAt",
        headerName: "Submit On",
        flex: 1,
        renderCell: (params) =>
          moment(params.row.createdAt).format("MM-DD-YYYY"),
      },
      {
        field: "waiting_status",
        headerName: "Waiting On",
        flex: 1,
        renderCell: (params) =>
          params.row?.waiting_status || 'N/A',
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) =>
          getStatus(params.row.status)
      },
    ],
    [rowId]
  );

  const handleRowClick = (params) => {
    navigate(`/contentdetails/${params.row.content_type?.toLowerCase()}/${params.row._id}`);
  };

  const convertToCSVData = async () => {
    setCsvLoading(true)
    let params = {
      page: 1,
      limit: totalDataSize?.all?.totalItems
    }
    if (selectedContainer.type !== 'all') {
      params.type = selectedContainer.type;
      params.limit = totalDataSize[selectedContainer.type].totalItems;
    }
    const apiData = await axios.get(`${process.env.REACT_APP_API_URI}content/learn-all`, { params: params })
      .then((response) => {
        return response?.data?.data
      })
      .catch((error) => {
        return error
      });
    const newCsvData = Array.isArray(apiData) ? apiData?.map((row) => ({
      "First Name": row?.coach?.first_name || '',
      "Last Name": row?.coach?.last_name || '',
      "Content Type": row.content_type,
      "Submit On": moment(row.createdAt).format("MM-DD-YYYY"),
      "Waiting On": row?.waiting_status || 'N/A',
      Status: row.status,
    })) : [];
    setCsvLoading(false)
    setCsvData(newCsvData)
  };

  useEffect(() => {
    if (csvData?.length > 0) {
      csvRef.current.link.click()
      setCsvData([])
    }
  }, [csvData])

  return (
    <Main open={open} style={{ background: "#f6f6f6", height: "100vh" }}>
      <DrawerHeader />
      <Box
        style={{
          height: 600,
          width: "100%",
        }}
      >
        <div className="count_cards">
          {learnData.map((item) =>
            <div
              className={`count_container ${selectedContainer.type === item.type ? "selected" : ""
                }`}
              onClick={() => setSelectedContainer(item)}
            >
              <div>
                <h3>{capitalizeText(item?.type)}</h3>
                <h3>{item.totalItems}</h3>
              </div>
            </div>
          )}
        </div>
        <div style={{ textAlign: "right", padding: "1rem" }}>
          <button
            onClick={() => convertToCSVData()}
            disabled={csvLoading}
            className="csv-download-link"
          >
            {csvLoading ? 'Downloading....' : 'Export to CSV'}
          </button>
          <CSVLink
            data={csvData}
            ref={csvRef}
            filename={"coaches_data.csv"}
            style={{ display: 'none' }}
          >
            Export to CSV
          </CSVLink>
        </div>
        <DataGrid
          columns={columns}
          rows={data}
          loading={loading}
          paginationMode="server"
          rowCount={totalDataSize?.currentCount?.totalItems}
          onPaginationModelChange={setPaginationModel}
          getRowId={(row) => row._id}
          pageSizeOptions={[5, 10, 20, 25]}
          paginationModel={paginationModel}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[50] : grey[900],
            },
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
          onRowClick={handleRowClick}
          style={{ cursor: "pointer" }}
        />
      </Box>
    </Main>
  );
}

export default CoachContent;
