import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { Main } from "../Sidebar/MainBody/Main";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import Options from "./Options";

function Questions({ open, setTitle }) {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [count, setCount] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URI}user/questions`)
      .then((response) => {
        setData(response.data.questions);
        setCount(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    setTitle("Questions");
  }, []);

  const columns = useMemo(
    () => [
      { field: "question", headerName: "Question", flex: 1 },
      { field: "description", headerName: "Description", flex: 1 },
      {
        field: "options",
        headerName: "Options",
        flex: 1,
        renderCell: (params) => <Options options={params.row.options} />,
      },
      { field: "type", headerName: "type", flex: 1 },
    ],
    [rowId]
  );

  return (
    <>
      <Main open={open}>
        <DrawerHeader />
        <Box
          style={{
            height: 600,
            width: "100%",
          }}
        >
          <DataGrid
            columns={columns}
            rows={data}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={(params) => ({
              top: params.isFirstVisible ? 0 : 5,
              bottom: params.isLastVisible ? 0 : 5,
            })}
            sx={{
              [`& .${gridClasses.row}`]: {
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[200] : grey[900],
              },
            }}
            onCellEditCommit={(params) => setRowId(params.id)}
          />
        </Box>
      </Main>
    </>
  );
}

export default Questions;
