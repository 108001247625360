import React from "react";

const QuestionAnswerComponent = ({ backendData, jsonData }) => {
  const containerStyle = {
    marginBottom: "20px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  const questionStyle = {
    marginBottom: "10px",
  };

  // Function to find the question from jsonData based on the question number
  const findQuestionByNumber = (dimensionName, questionNumber) => {
    const dimension = jsonData.find(
      (item) => item.dimension_name === dimensionName
    );
    if (dimension) {
      const question = dimension.question.find(
        (item) => item.number === questionNumber
      );
      return question ? question.question : null;
    }
    return null;
  };

  // Function to capitalize the dimension name
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Function to render the questions and answers for a specific dimension
  const renderDimensionQuestions = (dimension) => {
    return dimension.answers.map((answer) => (
      <div key={answer.number}>
        <p style={questionStyle}>
          <span style={{ fontWeight: "bold" }}>Question - {answer.number}</span>
          : {findQuestionByNumber(dimension.dimension_name, answer.number)}
        </p>
        <p style={questionStyle}>
          <span style={{ fontWeight: "bold" }}>Answer:</span> {answer.answer}
        </p>
      </div>
    ));
  };

  // Function to render dimensions with their questions and answers
  const renderDimensions = () => {
    return backendData.map((dimension) => (
      <div key={dimension.dimension_name} style={containerStyle}>
        <h3 style={{ marginBottom: "0.5rem" }}>
          {capitalize(dimension.dimension_name)}
        </h3>
        {renderDimensionQuestions(dimension)}
      </div>
    ));
  };

  return <div>{renderDimensions()}</div>;
};

export default QuestionAnswerComponent;
