import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const MonthlySignUpChart = ({ title, color, signupData }) => {
  // Extracting day labels and signup counts from the data
  const labels = signupData.map((item) => item.day);
  const counts = signupData.map((item) => item.count);

  // Chart data object
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sign Ups",
        data: counts,
        fill: false,
        backgroundColor: `${color}`,
        borderColor: `${color}`,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Day",
        },
      },
      y: {
        title: {
          display: true,
          text: "Monthly Sign Up",
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <h5>Sign Up Count ({title})</h5>
      <div className="chart-wrapper">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default MonthlySignUpChart;
