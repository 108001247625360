import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const QuarterlySignUpChart = ({ title, color, signupData }) => {
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-container">
      <h5>Sign Up Count ({title})</h5>
      <div className="chart-wrapper">
        <Bar
          data={{
            labels: signupData.map((entry) => entry.quarter),
            datasets: [
              {
                label: "Sign Ups",
                data: signupData.map((entry) => entry.count),
                backgroundColor: `${color}`,
              },
            ],
          }}
          options={chartOptions}
        />
      </div>
    </div>
  );
};

export default QuarterlySignUpChart;
