import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const YearlySignUpChart = ({ title, color, signupCounts }) => {
  // Extract month names and count values from signupCounts
  const months = signupCounts.map((entry) => entry.month);
  const counts = signupCounts.map((entry) => entry.count);

  // Line chart data
  const chartData = {
    labels: months,
    datasets: [
      {
        label: "Sign Ups",
        data: counts,
        fill: false,
        backgroundColor: `${color}`,
        borderColor: `${color}`,
        tension: 0.1,
      },
    ],
  };

  // Line chart options
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        precision: 0,
      },
    },
  };

  return (
    <div className="chart-container">
      <h5>Sign Up Count ({title})</h5>
      <div className="chart-wrapper">
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default YearlySignUpChart;
