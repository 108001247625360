import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Main } from "../Sidebar/MainBody/Main";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import { Box, CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import "./ContentDetails.css";
import { capitalizeText, getStatus } from "../utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ContentDetails({ open, setTitle }) {
  const navigate = useNavigate();

  const { content_type, content_id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [waitingOn, setWaitingOn] = useState("N/A");
  const [logs, setLogs] = useState();
  const [log, setLog] = useState();
  const [feedback, setFeedback] = useState('');
  const [viewContentType, setViewContentType] = useState("");

  const [openL, setOpenL] = useState(false);
  const [openT, setOpenT] = useState(false);
  const [openA, setOpenA] = useState(false);
  const [openR, setOpenR] = useState(false);

  async function createOrUpdateWaitingStatus(status) {
    try {
      const variable = {
        user: data.user,
        status: status,
        content_id: content_id,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URI}content/waiting_create_or_update`,
          variable
        )
        .then((response) => {
          if (response.status === 200) {
            setWaitingOn(response?.data?.status);
          }
          if (response.status === 201) {
            setWaitingOn(response?.data?.status);
          }
          setOpenT(true);

          setTimeout(() => {
            setOpenT(false);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function getWaitingStatus() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}content/get_waiting_status`,
        { content_id: content_id }
      );

      if (response.status === 200) {
        // Assuming the response.data contains the waiting status
        setWaitingOn(response.data.status);
      } else if (response.status === 404) {
        // Handle the case when the resource is not found
        // You can decide whether to create or update the waiting status here
        createOrUpdateWaitingStatus(waitingOn);
      } else {
        // Handle other HTTP status codes if needed
        console.error("Unexpected HTTP status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function shortFormAction(status) {
    try {
      const variable = {
        status: status,
        comments: feedback,
      };
      axios
        .put(
          `${process.env.REACT_APP_PROGRAMS_API_URI}api/approve/blog/${content_id}`,
          variable
        )
        .then((response) => {
          if (response.status === 200) {
            setOpenT(true);

            setTimeout(() => {
              setOpenT(false);
              navigate("/coachcontent");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function videoAction(status) {
    try {
      const variable = {
        status: status,
        comments: feedback,
      };
      axios
        .put(
          `${process.env.REACT_APP_PROGRAMS_API_URI}api/approve/program/${content_id}`,
          variable
        )
        .then((response) => {
          if (response.status === 200) {
            setOpenT(true);

            setTimeout(() => {
              setOpenT(false);
              navigate("/coachcontent");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function challengesAction(status) {
    try {
      const variable = {
        status: status,
        comments: feedback,
      };
      axios
        .put(
          `${process.env.REACT_APP_PROGRAMS_API_URI}api/approve/challange/${content_id}`,
          variable
        )
        .then((response) => {
          if (response.status === 200) {
            setOpenT(true);

            setTimeout(() => {
              setOpenT(false);
              navigate("/coachcontent");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function getContentLogs() {
    try {
      const variable = {
        content_id: content_id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}content/getLogs`,
        variable
      ); // Adjust the API endpoint as needed
      if (response.status === 200) {
        setLogs(response.data);
      }
      if (response.status === 404) {
        setLogs([]);
      }
    } catch (error) {
      console.error("Error fetching content logs:", error);
      throw error;
    }
  }

  async function createContentLog() {
    try {
      const variable = {
        content_id: content_id,
        user: data.user,
        log_text: log,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}content/createLog`,
        variable
      ); // Adjust the API endpoint as needed
      if (response.status === 201) {
        getContentLogs();
      }
    } catch (error) {
      console.error("Error creating content log:", error);
      throw error;
    }
  }

  const handleChangeWaiting = (event) => {
    setWaitingOn(event.target.value);
    // createOrUpdateWaitingStatus(event.target.value);
  };

  const viewContent = () => {
    // let contentUrl = `${process.env.REACT_APP_PROGRAMS_API_URI_CONTENT}${viewContentType}/${content_id}`;

    // if (viewContentType === "challenges") {
    //   contentUrl = `${process.env.REACT_APP_PROGRAMS_API_URI_CONTENT}challenges/detail/${content_id}`;
    // }

    // const urlWithToken = `${contentUrl}/?token=${process.env.REACT_APP_PROGRAMS_TOKEN}`;

    // window.open(urlWithToken, "_blank");
  };

  const handleCloseL = () => {
    setOpenL(false);
  };

  const handleNavigate = ()=> {
    if(content_type === 'todo'){
      window.open(`https://app.wellavi.com/self-coaching/do/do-detail?id=${content_id}`)
    }else{
       window.open(`https://app.wellavi.com/self-coaching/learn/${content_type}/${content_type}-detail?id=${content_id}`)
    }
    console.log('data:::',data);
  }

  const handleCloseA = (value) => {
    setOpenA(false);
    if (value !== 'checked') {
      setTimeout(() => {
        setCurrentStatus(data?.status)
      }, 100);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenT(false);
  };

  const handleOpenA = (status) => {
    setCurrentStatus(status)
    setOpenA(true)
  }

  const handleUpdateStatus = async (key, value, callbackFn) => {
    if (callbackFn) {
      callbackFn('checked')
    }
    setLoading(true)
    const type = content_type?.toLowerCase()
    const apiUrl = `${process.env.REACT_APP_WELLAVI_API_URI}/enterprise/${type}/update-${type}/${content_id}`;
    let body = {}
    if (key === 'internal_logs') {
      body = {
        internal_logs: value,
        status: currentStatus?.toLowerCase()
      }
    } else {
      body = {
        [key]: value,
        feedback: feedback
      }
    }
    try {
      const response = await axios.patch(apiUrl, { ...body });
      if (response.status === 200) {
        setOpenT(true)
        handleDetailApi()
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleDetailApi = async () => {
    setLoading(true)
    const type = content_type?.toLowerCase()
    const apiUrl = `${process.env.REACT_APP_WELLAVI_API_URI}/enterprise/${type}/get-${type}/${content_id}`;
    try {
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        setData(response?.data?.data?.[0] || response?.data?.data);
        setCurrentStatus(response?.data?.data?.[0]?.status || response?.data?.data?.status)
        setFeedback(response?.data?.data?.[0]?.feedback || response?.data?.data?.feedback)
        setLog(data?.internal_logs || '')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (content_type && content_id) {
      handleDetailApi()
    }
    setTitle("Content Content > Item View");
  }, []);

  return (
    <Main
      open={open}
      style={{
        background: "#F6F6F6",
        position: 'relative'
      }}
    >
      {loading &&
        <div style={{
          display: 'flex',
          zIndex: 999,
          position: 'absolute',
          marginLeft: '-30px',
          backgroundColor: 'rgba(0,0,0,0.5)',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}>
          <CircularProgress size={70} color="inherit" />
        </div>
      }
      <>
        <DrawerHeader />
        <div className="content-buttons-container">
          <button
            className="back-button"
            onClick={() => navigate("/coachcontent")}
          >
            BACK
          </button>
        </div>
        <div className="content_container_box">
          <h4 className="title">Content Details</h4>
          <div className="content-flex">
            <div className="content_left_section">
              <div className="items">
                <label className="sub-title">Date of Submission</label>
                <div className="content-boarder">
                  {moment(data.createdAt).format("MM-DD-YYYY")}
                </div>
              </div>
              <div className="items">
                <label className="sub-title">Status</label>
                <div className="content-boarder">{getStatus(data.status)}</div>
              </div>
              <div className="items">
                <label className="sub-title">Content Type</label>
                <div className="content-boarder">{capitalizeText(content_type)}</div>
              </div>
              <div className="items">
                <label className="sub-title">Waiting on</label>
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <Select
                      value={waitingOn}
                      onChange={handleChangeWaiting}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="options"
                    >
                      <MenuItem value={"Coach"}>Coach</MenuItem>
                      <MenuItem value={"Wellavi"}>Wellavi</MenuItem>
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="content_right_section">
              <div className="items">
                <label className="sub-title">Content Title</label>
                <div className="content-boarder">
                  {data.title ? data.title : data.name}
                </div>
              </div>
              <button className="response-button" onClick={handleNavigate}>
                View Content
              </button>
              <br />
              <br />
              <label className="sub-title">Feedback for Coach</label>
              <br />
              <textarea
                className="feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
              <br />
              <br />
              <div className="content-buttons-container">
                <button className="response-button" aria-hidden="false" onClick={() => handleOpenA('active')}>
                  Approve Content
                </button>
                <button className="response-button" onClick={() => handleOpenA('rejected')}>
                  Deny Content
                </button>
              </div>
              <div className="items">
                <label className="sub-title">Internal Log Notes</label>
                <div className="content-log">
                  {data?.internal_logs}
                </div>
              </div>
              <button className="response-button" onClick={() => setOpenL(true)}>
                Add a manual log note
              </button>
              <br />
            </div>
          </div>
        </div>
        <Snackbar
          open={openT}
          onClose={handleClose}
          autoHideDuration={3000}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Changes Saved!
          </Alert>
        </Snackbar>
        <Dialog
          open={openL}
          onClose={handleCloseL}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <textarea
            type="text"
            value={log}
            className="dialog"
            onChange={(e) => setLog(e.target.value)}
          />
          <DialogActions>
            <Button autoFocus onClick={() => handleUpdateStatus('internal_logs', log, handleCloseL)}>
              ADD
            </Button>
            <Button onClick={handleCloseL}>CANCEL</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openA}
          onClose={handleCloseA}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure want to ${currentStatus === 'active' ? 'approve' : 'reject'} this content?`}
          </DialogTitle>

          <DialogActions>
            <Button onClick={() => handleUpdateStatus('status', currentStatus, handleCloseA)} autoFocus>
              YES
            </Button>
            <Button onClick={handleCloseA}>NO</Button>
          </DialogActions>
        </Dialog>
      </>
    </Main>
  );
}

export default ContentDetails;
