import React from "react";

const AssessmentComponent = ({ questions, answers }) => {
  const containerStyle = {
    margin: "auto",
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const questionStyle = {
    fontWeight: "bold",
  };

  const answerStyle = {
    color: "rgba(121, 47, 138, 1)",
  };

  return (
    <div>
      {questions.map((question, index) => (
        <div style={containerStyle} key={question.id}>
          <p style={questionStyle}>{question.question}</p>
          <p style={answerStyle}>
            Answer : {answers?.[index]?.answer === "1" && "Manageable"}
            {answers?.[index]?.answer === "2" && "Adequate"}
            {answers?.[index]?.answer === "3" && "Good"}
            {answers?.[index]?.answer === "4" && "Strong"}
            {answers?.[index]?.answer === "5" && "Excellent"}
          </p>
        </div>
      ))}
    </div>
  );
};

export default AssessmentComponent;
