import React from "react";

function Options({ options, type }) {
  return (
    <div>
      {type === "session" ? (
        <>
          {options?.[0]?.dimentionSelector?.result?.map((r) => (
            <li style={{ listStyle: "none" }}> {r.name}</li>
          ))}
        </>
      ) : (
        <>
          {options.map((option) => (
            <li style={{ listStyle: "none" }}>{option.title}</li>
          ))}
        </>
      )}
    </div>
  );
}

export default Options;
