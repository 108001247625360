// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Set responsive styles for DataGrid */
.MuiDataGrid-root {
  height: 400px;
  width: 100%;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .MuiDataGrid-root {
    height: 300px;
  }
}
/* Add this CSS in the same component's CSS file or in a separate CSS file imported into the component */
/* This will make the table responsive and scroll horizontally on smaller screens */

/* Adjust the breakpoint as needed */
@media screen and (max-width: 768px) {
  .MuiDataGrid-root {
    overflow: auto;
  }
}
.csv-download-link {
  text-decoration: none;
  color: #7f3391;
}
`, "",{"version":3,"sources":["webpack://./src/components/Referrals/Referrals.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;EACE,aAAa;EACb,WAAW;AACb;;AAEA,wCAAwC;AACxC;EACE;IACE,aAAa;EACf;AACF;AACA,wGAAwG;AACxG,mFAAmF;;AAEnF,oCAAoC;AACpC;EACE;IACE,cAAc;EAChB;AACF;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":["/* Set responsive styles for DataGrid */\n.MuiDataGrid-root {\n  height: 400px;\n  width: 100%;\n}\n\n/* Add media query for smaller screens */\n@media (max-width: 768px) {\n  .MuiDataGrid-root {\n    height: 300px;\n  }\n}\n/* Add this CSS in the same component's CSS file or in a separate CSS file imported into the component */\n/* This will make the table responsive and scroll horizontally on smaller screens */\n\n/* Adjust the breakpoint as needed */\n@media screen and (max-width: 768px) {\n  .MuiDataGrid-root {\n    overflow: auto;\n  }\n}\n.csv-download-link {\n  text-decoration: none;\n  color: #7f3391;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
