// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.count_container_app {
  width: 191px;
  height: 51px;
  color: #111827;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  background-color: #fff;
}
.count_container_app.selected {
  background-color: #792f8a;
  color: #ffffff;
}
.count_container_app h3 {
  font-size: 14px;
  font-weight: 500;
}
.count_container_app p {
  font-size: 10px;
  font-weight: 500;
}
/* Add media query for smaller screens */
@media (max-width: 768px) {
  .count_container_app {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CoachApplications/CoachApplications.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA,wCAAwC;AACxC;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".count_cards {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  margin-bottom: 1.5rem;\n}\n\n.count_container_app {\n  width: 191px;\n  height: 51px;\n  color: #111827;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  align-items: center;\n  cursor: pointer;\n  margin: 10px;\n  background-color: #fff;\n}\n.count_container_app.selected {\n  background-color: #792f8a;\n  color: #ffffff;\n}\n.count_container_app h3 {\n  font-size: 14px;\n  font-weight: 500;\n}\n.count_container_app p {\n  font-size: 10px;\n  font-weight: 500;\n}\n/* Add media query for smaller screens */\n@media (max-width: 768px) {\n  .count_container_app {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
