import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { Main } from "../Sidebar/MainBody/Main";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import { CSVLink } from "react-csv";

import "./Referrals.css";

const Referrals = ({ open, setTitle }) => {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URI}user/referrals`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setTitle("Referrals");
  }, []);

  const columns = useMemo(
    () => [
      { field: "first_name", headerName: "First Name", flex: 1 },
      { field: "last_name", headerName: "Last Name", flex: 1 },
      { field: "role", headerName: "Role", flex: 1 },
      { field: "referred_users", headerName: "Referred Users", flex: 1 },
      { field: "referred_coaches", headerName: "Referred Coaches", flex: 1 },
    ],
    [rowId]
  );

  const convertToCSVData = (data) => {
    const csvData = data.map((row) => ({
      "First Name": row.first_name,
      "Last Name": row.last_name,
      Role: row.role,
      "Referred Users": row.referred_users,
      "Referred Coaches": row.referred_coaches,
    }));

    return csvData;
  };

  return (
    <Main open={open} style={{ background: "#f6f6f6", height: "100vh" }}>
      <DrawerHeader />
      <Box
        style={{
          height: 600,
          width: "100%",
        }}
      >
        <div style={{ textAlign: "right", padding: "1rem" }}>
          <CSVLink
            data={convertToCSVData(data)}
            filename={"referrals_data.csv"}
            className="csv-download-link"
          >
            Export to CSV
          </CSVLink>
        </div>
        <DataGrid
          columns={columns}
          rows={data}
          getRowId={(row) => row._id}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[50] : grey[900],
            },
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </Main>
  );
};

export default Referrals;
