import React, { useEffect, useState } from "react";
import { Main } from "../Sidebar/MainBody/Main";
import axios from "axios";
import { DrawerHeader } from "../Drawerheader/Drawerheader";
import WeeklySignUpChart from "../WeeklySignUpChart/WeeklySignUpChart";
import QuarterlySignUpChart from "../QuarterlySignUpChart/QuarterlySignUpChart";
import MonthlySignUpChart from "../MonthlySignUpChart/MonthlySignUpChart";
import YearlySignUpChart from "../YearlySignUpChart/YearlySignUpChart";
import "./Dashboard.css";

function Dashboard({ open, setTitle }) {
  const [userWeek, setUserWeek] = useState([]);
  const [userMonth, setUserMonth] = useState([]);
  const [userQuarter, setUserQuarter] = useState([]);
  const [userYtd, setUserYtd] = useState([]);
  const [coachWeek, setCoachWeek] = useState([]);
  const [coachMonth, setCoachMonth] = useState([]);
  const [coachQuarter, setCoachQuarter] = useState([]);
  const [coachYtd, setCoachYtd] = useState([]);
  const [userWeekCount, setUserWeekCount] = useState(0);
  const [userMonthCount, setUserMonthCount] = useState(0);
  const [userQuarterCount, setUserQuarterCount] = useState(0);
  const [userYearCount, setUserYearCount] = useState(0);
  const [coachWeekCount, setCoachWeekCount] = useState(0);
  const [coachMonthCount, setCoachMonthCount] = useState(0);
  const [coachQuarterCount, setCoachQuarterCount] = useState(0);
  const [coachYearCount, setCoachYearCount] = useState(0);
  const [selectedContainer, setSelectedContainer] = useState("");
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [showButtons, setShowButtons] = useState();
  const [showbar, setShowBar] = useState();

  const getCounts = async () => {
    let countUserWeek = 0;
    let countUserMonth = 0;
    let countUserQuarter = 0;
    let countUserYear = 0;
    let countCoachWeek = 0;
    let countCoachMonth = 0;
    let countCoachQuarter = 0;
    let countCoachYear = 0;

    await userWeek.map((item) => (countUserWeek += item.count));
    await userMonth.map((item) => (countUserMonth += item.count));
    await userQuarter.map((item) => (countUserQuarter += item.count));
    await userYtd.map((item) => (countUserYear += item.count));
    await coachWeek.map((item) => (countCoachWeek += item.count));
    await coachMonth.map((item) => (countCoachMonth += item.count));
    await coachQuarter.map((item) => (countCoachQuarter += item.count));
    await coachYtd.map((item) => (countCoachYear += item.count));

    setUserWeekCount(countUserWeek);
    setUserMonthCount(countUserMonth);
    setUserQuarterCount(countUserQuarter);
    setUserYearCount(countUserYear);
    setCoachWeekCount(countCoachWeek);
    setCoachMonthCount(countCoachMonth);
    setCoachQuarterCount(countCoachQuarter);
    setCoachYearCount(countCoachYear);
  };

  useEffect(() => {
    // Fetch signup counts data from the backend API
    axios
      .get(`${process.env.REACT_APP_API_URI}user/user-signups-week`)
      .then((response) => {
        setUserWeek(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}user/user-signups-month`)
      .then((response) => {
        setUserMonth(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}user/user-signups-quarter`)
      .then((response) => {
        setUserQuarter(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}user/user-signups-ytd`)
      .then((response) => {
        setUserYtd(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}coach/coach-signups-week`)
      .then((response) => {
        setCoachWeek(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}coach/coach-signups-month`)
      .then((response) => {
        setCoachMonth(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}coach/coach-signups-quarter`)
      .then((response) => {
        setCoachQuarter(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URI}coach/coach-signups-ytd`)
      .then((response) => {
        setCoachYtd(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setTitle("Dashaboard");
  }, []);

  useEffect(() => {
    getCounts();
  }, [
    userWeek,
    userMonth,
    userQuarter,
    userYtd,
    coachWeek,
    coachMonth,
    coachQuarter,
    coachYtd,
  ]);

  const handleShowBar = (containerName) => {
    setSelectedContainer(containerName);
    setShowBar(containerName);
  };

  const handleWelcome = (data, type) => {
    setShowWelcomeMessage(false);
    setShowBar(data);
    setShowButtons(type);
    if (type === "user") {
      setSelectedContainer("userWeek");
      setTitle("Dashboard > Users");
    } else {
      setSelectedContainer("coachWeek");
      setTitle("Dashboard > Coaches");
    }
  };

  return (
    <>
      <Main open={open} style={{ background: "#F6F6F6", height: "100vh" }}>
        <DrawerHeader />
        {showWelcomeMessage && (
          <div className="welcome_message_container">
            <div className="welcome_message">
              <h2>Welcome!</h2>
              <p>Please select what data you would like to review today</p>
              <div className="welcome_buttons">
                <button
                  className="welcome_button"
                  onClick={() => handleWelcome("userWeek", "user")}
                >
                  Users
                </button>
                <button
                  className="welcome_button"
                  onClick={() => handleWelcome("coachWeek", "coach")}
                >
                  Coaches
                </button>
              </div>
            </div>
          </div>
        )}

        {showButtons === "user" && (
          <div className="count_cards">
            <div
              className={`count_container ${
                selectedContainer === "userWeek" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("userWeek")}
            >
              <div>
                <h3>Singups This Week</h3>
                <h3>{userWeekCount}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "userMonth" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("userMonth")}
            >
              <div>
                <h3>Singups This Month</h3>
                <h3>{userMonthCount}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "userQuarter" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("userQuarter")}
            >
              <div>
                <h3>Singups Quarterly</h3>
                <h3>{userQuarterCount}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "userYtd" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("userYtd")}
            >
              <div>
                <h3>Singups This Year</h3>
                <h3>{userYearCount}</h3>
              </div>
            </div>
          </div>
        )}
        {showButtons === "coach" && (
          <div className="count_cards">
            <div
              className={`count_container ${
                selectedContainer === "coachWeek" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("coachWeek")}
            >
              <div>
                <h3>Singups This Week</h3>
                <h3>{coachWeekCount}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "coachMonth" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("coachMonth")}
            >
              <div>
                <h3>Singups This Month</h3>
                <h3>{coachMonthCount}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "coachQuarter" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("coachQuarter")}
            >
              <div>
                <h3>Singups Quarterly</h3>
                <h3>{coachQuarterCount}</h3>
              </div>
            </div>
            <div
              className={`count_container ${
                selectedContainer === "coachYtd" ? "selected" : ""
              }`}
              onClick={() => handleShowBar("coachYtd")}
            >
              <div>
                <h3>Singups This Year</h3>
                <h3>{coachYearCount}</h3>
              </div>
            </div>
          </div>
        )}

        {showbar === "userWeek" && (
          <WeeklySignUpChart
            title={"Users"}
            color={"rgba(202, 135, 57, 1)"}
            signupData={userWeek}
          />
        )}
        {showbar === "userMonth" && (
          <MonthlySignUpChart
            title={"Users"}
            color={"rgba(202, 135, 57, 1)"}
            signupData={userMonth}
          />
        )}
        {showbar === "userQuarter" && (
          <QuarterlySignUpChart
            title={"Users"}
            color={"rgba(202, 135, 57, 1)"}
            signupData={userQuarter}
          />
        )}
        {showbar === "userYtd" && (
          <YearlySignUpChart
            title={"Users"}
            color={"rgba(202, 135, 57, 1)"}
            signupCounts={userYtd}
          />
        )}
        {showbar === "coachWeek" && (
          <WeeklySignUpChart
            title={"Coaches"}
            color={"rgba(194, 43, 92, 1)"}
            signupData={coachWeek}
          />
        )}
        {showbar === "coachMonth" && (
          <MonthlySignUpChart
            title={"Coaches"}
            color={"rgba(194, 43, 92, 1)"}
            signupData={coachMonth}
          />
        )}
        {showbar === "coachQuarter" && (
          <QuarterlySignUpChart
            title={"Coaches"}
            color={"rgba(194, 43, 92, 1)"}
            signupData={coachQuarter}
          />
        )}
        {showbar === "coachYtd" && (
          <YearlySignUpChart
            title={"Coaches"}
            color={"rgba(194, 43, 92, 1)"}
            signupCounts={coachYtd}
          />
        )}
      </Main>
    </>
  );
}

export default Dashboard;
