import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../Images/Wellavi_Logo.png";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Sidebar({
  open,
  setOpen,
  isLoggedIn,
  setIsLoggedIn,
  title,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onClickRoute = (route) => {
    if (route === "coach content") {
      navigate(`/coachcontent`);
    } else {
      navigate(`/${route}`);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(!isLoggedIn);
    localStorage.removeItem("wellaviemail");
    localStorage.removeItem("wellavipassword");
    navigate(`/`);
  };

  const isActive = (route) => {
    if (location.pathname === "/coachcontent" && route === "coach content") {
      return true;
    } else {
      return location.pathname === `/${route}`;
    }
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
            style={{ color: "#111827" }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{
                textTransform: "capitalize",
                color: "#111827",
                fontSize: "24px",
              }}
            >
              {title}
            </Typography>
          </div>
        </Toolbar>
        <div
          style={{
            padding: "2px 0",
            background:
              "linear-gradient(90deg, #FAB826 0%, #EA7206 25.52%, #D71326 48.96%, #BF1D6A 75%, #7F3391 100%)",
          }}
        ></div>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <img src={logo} />
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
        </div>
        <Divider />
        <List>
          {[
            "dashboard",
            "users",
            "coaches",
            "coach content",
            "applications",
            // "profile",
            "referrals",
            "sessions",
            "questions",
            // "answers",
          ].map((text, index) => (
            <ListItem key={text}>
              <ListItemButton
                style={{
                  backgroundColor: isActive(text) ? "#F3F4F6" : "transparent",
                  borderRadius: "5px",
                  color: isActive(text) ? "#111827" : "#6B7280",
                  fontSize: "14px",
                }}
                onClick={() => onClickRoute(text)}
              >
                <img
                  src={require(`../Images/${text}.png`)}
                  style={{ marginRight: "0.6rem" }}
                />
                <ListItemText
                  primary={text}
                  style={{ textTransform: "capitalize" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem>
            <ListItemButton onClick={handleLogout}>
              <img
                src={require(`../Images/logout.png`)}
                style={{ marginRight: "0.9rem" }}
              />
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
